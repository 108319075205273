import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common'
import { Constant } from './providers/constant';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpRequestsService } from './services/http-requests.service';
import { LocalStorageService } from './services/local-storage.service';
import { UtilsService } from './services/utils.service';
import { WebsocketService } from './services/websocket.service';
import { Observable } from 'rxjs/internal/Observable';
import { EventDetailsComponent } from '../modules/admin/all-event/event-details/event-details.component';
@Injectable({
  providedIn: 'root'
})
export class CommonAPIService {
  model: BsModalRef;
  userDetails: any;
  constructor(
    private httpRequest: HttpRequestsService,
    private router: Router,
    private formBuilder: FormBuilder,
    private localstorege: LocalStorageService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private modalService: BsModalService,
    private utils: UtilsService,
    private websocketService: WebsocketService,
  ) {
    const storageValue: any = this.localstorege.getLocalStore(Constant.LOCAL_STORAGE_KEY.USER_DETAILS);
    this.userDetails = JSON.parse(storageValue);
  }

  async postLikeUnlike(details) {
    this.spinner.show();
    const data = {
      postId: details.id,
      isLike: !details.isLike
    }
    const result: any = await this.httpRequest.post(Constant.POTS_API_ROUTES.LIKE_UNLIKE, data);
    this.spinner.hide();
    return result;
  }

  async getMyStory() {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.GET_STORY_LIST, "");
    this.spinner.hide();
    return result;
  }
  async getUserStory() {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.GET_STORY_LISTS, "");
    this.spinner.hide();
    return result;
  }
  async getStoryById(id) {
    this.spinner.show();
    const result: any = await this.httpRequest.get(Constant.STORY_API_ROUTES.GET_STORY_BY_ID + '/' + id);
    this.spinner.hide();
    return result;
  }
  async getGymshots(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.POST_GYMSHOTS, data);
    this.spinner.hide();
    return result;
  }
  async reportGymshots(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.REPORT_GYMSHOTS, data);
    this.spinner.hide();
    return result;
  }
  async reportPostNew(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.REPORT_GYMPOST, data);
    this.spinner.hide();
    return result;
  }
  async likeGymshots(data) {
    const result: any = await this.httpRequest.post(Constant.CHAT_API.LIKE_GYMSHOTS, data);
    return result;
  }
  async likeGymshotsList(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.GET_GYMSHOTS_LIKE_LIST, data);
    this.spinner.hide();
    return result;
  }
  async getWhoviewMyStory(storyId) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.WHO_VIEW_MYSTORY + '/' + storyId, "");
    this.spinner.hide();
    return result;
  }
  async postAddStory(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.POST_STORY_ADD, data);
    this.spinner.hide();
    return result;
  }
  async postAddGymshot(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.POST_GYMSHOT_ADD, data);
    this.spinner.hide();
    return result;
  }
  async postShareStory(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.POST_STORY_SHARE, data);
    this.spinner.hide();
    return result;
  }
  async postUpdateStory(data, id) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.POST_STORY_UPDATE + '/' + id, data);
    this.spinner.hide();
    return result;
  }
  async postStoryReact(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.STORY_REACT, data);
    this.spinner.hide();
    return result;
  }
  async postStoryComment(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.STORY_COMMENT, data);
    this.spinner.hide();
    return result;
  }
  async postStoryDelete(storyId) {
    this.spinner.show();
    const result: any = await this.httpRequest.get(Constant.STORY_API_ROUTES.STORY_DELETE + '/' + storyId);
    this.spinner.hide();
    return result;
  }
  async getReportList() {
    this.spinner.show();
    const result: any = await this.httpRequest.get(Constant.USER_API_ROUTES.GET_REPORT_LIST);
    this.spinner.hide();
    return result;
  }
  async postStoryViewUpdate(storyId) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.UPDATE_VIEW_STORY + '/' + storyId, '');
    this.spinner.hide();
    return result;
  }
  async postAddTagStory(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.TAG_STORY, data);
    this.spinner.hide();
    return result;
  }
  async postLikeList(postId, pageNumber) {
    this.spinner.show();
    const data = {
      page: pageNumber,
      limit: Constant.LIMIT,
      postId: postId,
    }
    const result: any = await this.httpRequest.post(Constant.POTS_API_ROUTES.LIKE_LIST, data);
    this.spinner.hide();
    return result;
  }

  async postDetails(postId, pageNumber) {
    pageNumber > 1 ? this.spinner.show(this.utils.spinnerName) : this.spinner.show()
    const data = {
      page: pageNumber,
      limit: Constant.LIMIT,
      postId: postId,
    }
    const result: any = await this.httpRequest.post(Constant.POTS_API_ROUTES.POST_DETAILS, data);
    pageNumber > 1 ? this.spinner.hide(this.utils.spinnerName) : this.spinner.hide()
    return result;
  }

  redirectToUrl(user: any) {
    const storageValue: any = this.localstorege.getLocalStore(Constant.LOCAL_STORAGE_KEY.USER_DETAILS);
    this.userDetails = JSON.parse(storageValue);
    if (user.id !== this.userDetails.id) {
      this.router.navigate([`/other-user/${user.id}`]);
    } else {
      this.router.navigate(['/profile']);
    }
  }


  redirectToSettingUrl() {
    this.modalService?.hide()
    this.router.navigate([`/setting`]);
  }
  redirectToDetailsUrl(user: any) {
    this.modalService.hide();
    const storageValue: any = this.localstorege.getLocalStore(Constant.LOCAL_STORAGE_KEY.USER_DETAILS);
    this.userDetails = JSON.parse(storageValue);
    if (user.userId !== this.userDetails.id) {
      this.router.navigate([`/other-user/${user.userId}`]);
    } else {
      this.router.navigate(['/profile']);
    }
  }
  async hidePost(postId) {
    this.spinner.show();
    const data = {
      postId: postId
    }
    const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.HIDE_POST, data);
    if (result) {
      this.toastr.success(this.translate.instant(result.message))
      this.spinner.hide();
    }
    return result;
  }

  async reportPost(post) {
    this.spinner.show();
    const data = {
      "postId": post.id,
      "isReport": !post.isReport

    }
    const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.REPORT_POST, data);
    if (result) {
      this.toastr.success(this.translate.instant(result.message))
      this.spinner.hide();
    }
    return result;
  }

  async notificationList(pageNumber, limit) {
    this.spinner.show();
    const data = {
      page: pageNumber,
      limit: limit,
    }
    const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.NOTiFICATION, data);
    this.spinner.hide();
    return result;
  }

  async allNotificationRead() {
    const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.READ_NOTiFICATION_ALL, {});
    return result;
  }

  async bookmark(post) {
    this.spinner.show();
    let data = {
      "postId": post.id,
      "isBookmark": !post.isBookmark
    }
    const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.ADD_REMOVE_BOOKMARK, data);
    if (result) {
      this.toastr.success(this.translate.instant(result.message));
      this.spinner.hide();
      return result;
    }

  }
  // async bookmarkList(post) {
  //   this.spinner.show();
  //   let data = {
  //     "page": post.pageNumber,
  //     "limit": post.LIMIT,
  //     "uploadType": post.uploadType
  //   }
  //   const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.BOOKMARKLIST, data);
  //   if (result.code === 200) {
  //     this.toastr.success(this.translate.instant(result.message));
  //     this.spinner.hide();
  //   }
  // }

  async acceptRejectRequest(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.FOLLOW_API_ROUTES.ACCEPT_REJECT, data);
    this.toastr.success(result['message']);
    this.utils.sendUpdateRequest(Constant.UPDATE_SENT_TYPE.FRIEND_REQUEST)
    this.spinner.hide();
    return result;
  }

  async followUnfollowUser(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.FOLLOW_API_ROUTES.FOLLOW_UNFOLLOW, data);
    this.utils.sendUpdateRequest(Constant.UPDATE_SENT_TYPE.FOLLOW_UNFOLLOW)
    this.toastr.success(result['message']);
    this.spinner.hide();
    return result;
  }

  async readNotification(details) {
    this.spinner.show();
    const data = {
      notificationId: details.id
    }
    const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.READ_NOTiFICATION, data);
    this.spinner.hide();
    return result;
  }

  async blockUnblockUser(data) {
    this.spinner.show();
    const blockData = {
      blockUserId: data.userId,
      isBlock: data.isBlock
    }
    const result: any = await this.httpRequest.post(Constant.POTS_API_ROUTES.BLOCK_USER, blockData);
    if (result) {
      const blockEmitData = {
        userId: this.userDetails.id,
        chatRoomId: data.chatRoomId,
        isBlock: data.isBlock,
        blockedUserId: data.userId
      }
      this.websocketService.emit(Constant.SOCKET_EVENTS.BLOCK_EVENT, blockEmitData);
      this.toastr.success(result['message'])
    }
    this.spinner.hide();
  }

  async getWalletDetails(data) {
    this.spinner.show();
    let filter = '';
    let dateFilter = ''
    if (data.filter !== undefined) {
      filter = `&filter=${data.filter}`
    }
    if (data.dateFilter !== undefined) {
      dateFilter = `&dateFilter=${data.dateFilter}`
    }
    const result: any = await this.httpRequest.get(`${Constant.PAYMENT_API_ROUTES.GET_WALLET}?page=${data.page}&limit=${data.limit}${filter}${dateFilter}`);
    this.spinner.hide();
    return result;
  }

  async deletePost(postDetails) {
    this.spinner.show();
    const data = {
      postId: postDetails.id
    }
    const result: any = await this.httpRequest.post(Constant.POTS_API_ROUTES.DELETE, data);
    this.toastr.success(result['message']);
    this.spinner.hide();
    return result;
  }

  toggleSkilEvent(event) {
    if (event.counter < 361) {
      event.counter = event.description.length;
      event.showTxt = "...Less";
      event.isExpand = true;
    }
    else {
      event.counter = event.last_index;
      event.showTxt = "...More";
      event.isExpand = false;
    }
    return event;
  }

  async joinRoomForEvent(data) {
    this.websocketService.emit(Constant.SOCKET_EVENTS.EVENT_JOIN_ROOM, data);
  }

  // async listenEventStartEnable() {
  //   return this.websocketService.listen(Constant.SOCKET_EVENTS.EVENT_START_ENABLE);
  // }

  async joinEvent(data) {
    this.websocketService.emit(Constant.SOCKET_EVENTS.JOIN_EVENT, data);
  }

  async joinEventAPI(eventDetails) {
    this.spinner.show();
    const data = {
      eventId: eventDetails.eventId
    }
    const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.JOIN_EVENT_PARTICIPANTS, data);
    this.toastr.success(result['message']);
    if (result.code === 200) {
      this.router.navigate([`/core/event-live/${data.eventId}`],
        { queryParams: { channel: result.data.channelName, token: result.data.token }, queryParamsHandling: 'merge' })
    }
    this.spinner.hide();
    return result;
  }

  async startEvent(eventDetails) {
    this.spinner.show();
    const data = {
      eventId: eventDetails.id
    }
    const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.EVENT_START, data);
    this.toastr.success(result['message']);
    if (result.code === 200) {
      this.router.navigate([`/core/event-live/${eventDetails.id}`],
        { queryParams: { channel: result.data.channelName, token: result.data.token }, queryParamsHandling: 'merge' })
    }
    this.spinner.hide();
    return result;
  }

  async getAgoraToken(eventDetails) {
    this.spinner.show();
    const result: any = await this.httpRequest.get(`${Constant.USER_API_ROUTES.EVENT_START}/${eventDetails.id}`);
    this.spinner.hide();
    return result;
  }

  async startRecording(channel, eventId, uid, token) {
    this.spinner.show();
    const data = {
      eventId: eventId,
      channelName: channel,
      uid: `${uid}`,
      token: token
    }

    const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.START_RECORDING, data);
    this.spinner.hide();
    return result;
  }

  async stopRecording(channel, eventId) {
    this.spinner.show();
    const data = {
      eventId: eventId,
      channelName: channel
    }
    const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.STOP_RECORDING, data);
    this.spinner.hide();
    return result;
  }

  async endEvent(eventDetails) {
    this.spinner.show();
    const data = {
      eventId: eventDetails.id
    }
    const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.EVENT_END, data);
    this.toastr.success(result['message']);
    this.spinner.hide();
    return result;
  }

  async endEventAndSavePost(eventDetails, isTrue) {
    this.spinner.show();
    const data = {
      eventId: eventDetails.id,
      isPost: isTrue
    }
    const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.EVENT_END, data);
    this.toastr.success(result['message']);
    this.spinner.hide();
    return result;
  }

  async leaveEvent(eventDetails) {
    this.spinner.show();
    const data = {
      eventId: eventDetails.id
    }
    const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.EVENT_LEAVE, data);
    this.toastr.success(result['message']);
    this.spinner.hide();
    return result;
  }

  async getCountry() {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.COUNTRY_LIST, {}, true);
    this.spinner.hide();
    return result;
  }

  async getStates(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.STATE_LIST, data, true);
    this.spinner.hide();
    return result;
  }

  async getLanguages() {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.LANGUAGE_LIST, {}, true);
    this.spinner.hide();
    return result;
  }

  openModal(item) {
    const initialState = { data: item };
    this.model =
      this.modalService.show(EventDetailsComponent,
        { initialState, class: "live_event_full_modal" });
  }

  async deleteGymShot(gymShotId) {
    this.spinner.show();
    const data = {
      gymshotId: gymShotId
    }
    const result: any = await this.httpRequest.post(`${Constant.STORY_API_ROUTES.DELETE_GYMSHOT}`, data);
    this.toastr.success(result['message']);
    this.spinner.hide();
    return result;
  }
  async gymshotCommentsList(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.GET_GYMSHOTS_COMMENT_LIST, data);
    this.spinner.hide();
    return result;
  }
  async eventCommentsList(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.EVENT_COMMENT_DATA, data);
    this.spinner.hide();
    return result;
  }
  async gymshotDetails(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.GET_GYMSHOTS_DETAILS, data);
    this.spinner.hide();
    return result;
  }
  async getFollowersList(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.FOLLOW_API_ROUTES.FOLLOWERS, data);
    this.spinner.hide();
    return result;
  }
  async shareShot(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.SHARE_SHOT, data);
    this.spinner.hide();
    return result;
  } async shareShotAsStory(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.SHARE_AS_STORY, data);
    this.spinner.hide();
    return result;
  }
  async commentGymShot(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.COMMENT_GYMSHOT, data);
    this.spinner.hide();
    return result;
  }
  async editGymShot(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.EDIT_GYMSHOT, data);
    this.spinner.hide();
    return result;
  }

  async getAllAlbum(id) {
    this.spinner.show();
    const result: any = await this.httpRequest.get(Constant.GYM_FANS_ALBUM.GET_ALL_ALBUM + '/' + id);
    this.spinner.hide();
    return result;
  }
  async createAlbum(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.GYM_FANS_ALBUM.CREATE_ALBUM, data);
    this.spinner.hide();
    return result;
  }

  async uploadAlnumImages(id, data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.GYM_FANS_ALBUM.UPLOAD_Album_IMAGES + '/' + id, data);
    return result;
  }

  async getSingleAlbumDetails(id) {
    this.spinner.show();
    const result: any = await this.httpRequest.get(Constant.GYM_FANS_ALBUM.GET_SINGLE_ALBUM_DETAILS + '/' + id);
    this.spinner.hide();
    return result;
  }

  async removeSingleAlbum(id, data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.GYM_FANS_ALBUM.DELETE_ALBUME + '/' + id, data);
    this.spinner.hide();
    return result;
  }



  async getAllSupportTicket() {
    this.spinner.show();
    const result: any = await this.httpRequest.get(Constant.GYM_FANS_SUPPORT_TICKET.GET_ALL_SUPPORT_TICKET);
    this.spinner.hide();
    return result;
  }
  async getTicketDetails(id) {
    this.spinner.show();
    const result: any = await this.httpRequest.get(Constant.GYM_FANS_SUPPORT_TICKET.GET_TICKET_DETAILS + '/' + id);
    this.spinner.hide();
    return result;
  }
  async getTicketCategory() {
    this.spinner.show();
    const result: any = await this.httpRequest.get(Constant.GYM_FANS_SUPPORT_TICKET.GET_TICKET_CATEGORY);
    this.spinner.hide();
    return result;
  }
  async getSubTicketCategory(id) {
    this.spinner.show();
    const result: any = await this.httpRequest.get(Constant.GYM_FANS_SUPPORT_TICKET.GET_SUB_TICKET_CATEGORY + '/' + id);
    this.spinner.hide();
    return result;
  }
  async createTicket(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.GYM_FANS_SUPPORT_TICKET.CREATE_TICKET, data);
    this.spinner.hide();
    return result;
  }
  async uploadTicketImages(data, id) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.GYM_FANS_SUPPORT_TICKET.UPLOAD_TICKET_IMAGES + '/' + id, data);
    this.spinner.hide();
    return result;
  }

  async getFanChannelUserList(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.get(Constant.FAN_CHANNEL_ROUTES.GET_FAN_CHANNEL_USER_LIST, data);
    this.spinner.hide();
    return result;
  }

  async getAllFanChannelList(data) {
    const result: any = await this.httpRequest.get(Constant.FAN_CHANNEL_ROUTES.GET_ALL_FAN_CHANNEL_LIST, data);
    return result;
  }

  async createFanChannel(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.FAN_CHANNEL_ROUTES.CREATE_FAN_CHANNEL, data);
    this.spinner.hide();
    return result;
  }

  async editFanChannel(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.FAN_CHANNEL_ROUTES.EDIT_FAN_CHANNEL, data);
    this.spinner.hide();
    return result;
  }

  async uploadFanChannelProfile(data, id) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.FAN_CHANNEL_ROUTES.UPLOAD_FAN_CHANNEL_PROFILE + '/' + id, data);
    this.spinner.hide();
    return result;
  }

  async uploadImageFanMessage(data, id) {
    const result: any = await this.httpRequest.post(Constant.FAN_CHANNEL_ROUTES.UPLOAD_IMAGE_FAN_MESSAGE + '/' + id, data);
    return result;
  }

  async getFanChannelAddedUserList(id, searchString) {
    this.spinner.show();
    const result: any = await this.httpRequest.get(Constant.FAN_CHANNEL_ROUTES.GET_FAN_CHANNEL_ADDED_USER_LIST + '/' + id + '?searchString=' + searchString);
    this.spinner.hide();
    return result;
  }

  async removeMemberFromFanChannel(id, fanChannelId, userId) {
    this.spinner.show();
    const result: any = await this.httpRequest.get(Constant.FAN_CHANNEL_ROUTES.REMOVE_FAN_CHANNEL_MEMBER + '/' + id + '?fanChannelId=' + fanChannelId + '&userId=' + userId);
    this.spinner.hide();
    return result;
  }

  async deleteFanChannel(id) {
    this.spinner.show();
    const result: any = await this.httpRequest.get(Constant.FAN_CHANNEL_ROUTES.DELETE_FAN_CHANNEL + '/' + id);
    this.spinner.hide();
    return result;
  }

  async getFanChannelChatHistory(id, queryParams) {
    const result: any = await this.httpRequest.get(Constant.FAN_CHANNEL_ROUTES.GET_FAN_CHANNEL_CHAT_HISTORY + '/' + id + queryParams);
    return result;
  }

  async addParticipantsInFanChannel(id, queryParams) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.FAN_CHANNEL_ROUTES.ADD_USER_IN_FAN_CHANNEL + '/' + id, queryParams);
    this.spinner.hide();
    return result;
  }

  async readMessageOfFanChannel(data) {
    const result: any = await this.httpRequest.post(Constant.FAN_CHANNEL_ROUTES.READ_MESSAGE_FAN_CHANNEL, data);
    return result;
  }

  async reportStroyNew(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.REPORT_STORY, data);
    this.spinner.hide();
    return result;
  }

  async deleteComment(id) {
    this.spinner.show();
    const result: any = await this.httpRequest.deleteWithBody(`${Constant.STORY_API_ROUTES.DELETE_COMMENT}`, {}, false, { postCommentId: id });
    this.spinner.hide();
    return result;
  }

  async deleteGymShotComment(data) {
    this.spinner.show();
    const result: any = await this.httpRequest.post(Constant.STORY_API_ROUTES.DELETE_GYMSHOT_COMMENT, data);
    this.spinner.hide();
    return result;
  }
}
