export const environment = {
	production: false,
	env: 'DEV',
	APP_URL: 'https://dev.gymfans.com/api',
	EVENTURL: 'https://dev.gymfans.com/event',
	WEBSOCKET_URL: "https://dev.gymfans.com",
	ADD_URL: 'https://pubads.g.doubleclick.net/gampad/ads?' +
		'sz=640x480&iu=/124319096/external/single_ad_samples&ciu_szs=300x250&' +
		'impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&' +
		'cust_params=deployment%3Ddevsite%26sample_ct%3Dlinear&correlator=',
	AGORA_APP_ID: 'a2be722e9de64d27a0bc9d5a97767cd8',
	GOOGLE_CLIENT_KEY: '724309048326-c1uif8ro7ua56svh1uqrfqqqq2lmoitp.apps.googleusercontent.com',
	// GOOGLE_CLIENT_KEY: '262603427063-1br6o6d8dmuvabrlsnu50e8lsqcvlvti.apps.googleusercontent.com',
	// GOOGLE_SECRET: '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',
	FACEBOOK_CLIENT_KEY: '616273392978319',
	FACEBOOK_SECRET: '31637a6367e821f40e41f829b72ef13d',
	APPLE_CLIENT_ID: 'com.gymfans.dev.website',
	APPLE_REDIRECT_URL: 'https://dev.gymfans.com/api/um/v1/user/redirect-apple-signin',
	GOOGLE_PLACE_API: 'AIzaSyBSLdJKIeD9Ozb_zxNT_k5Q_bK0r5EmRDI',
	STRIPE_PUB_KEY: "pk_test_51Jdij9B3J6EdDqx9mN0xnEOI3G5aHHgx35lmS0h9MGKfw6heF69LB0k9b1H7Pf7B0t7lf68qLc1ShRp4la1MMM4P00SM0gHIkM",
	firebaseConfig: {
		apiKey: "AIzaSyAjFLqtahYyED_szIzWeVWGjOrkhfmc4X8",
		authDomain: "gymfans-cfa0b.firebaseapp.com",
		projectId: "gymfans-cfa0b",
		storageBucket: "gymfans-cfa0b.appspot.com",
		messagingSenderId: "262603427063",
		appId: "1:262603427063:web:d5f0b18beca43492fa97f2",
		measurementId: "G-9B2813YZH0"
	},
	AWS_SECRET_ACCESS_KEY: "rj5TkjpNM6a2VAEvIxPor43wsY/wYzvjHrfoSFAI",
	AWS_ACCESS_KEY_ID: "AKIAYGBPLXILTEIYPS7Q",
	AWS_REGION: "eu-west-1",
	AWS_BUCKET: "dev-media-original-gymfans-latest",
	RECORDING_BUCKET: "dev-event-recordings",
	DEEP_AR_LICENCE: "a36955e34c443bdb551fa077ca0065296aeb6d1cb6afd10ddb0437499b58d381053e9be5f821a464"
};