import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsService } from './services/utils.service';
import { ValidatorsComponent } from './validators/validators.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileDragNDropDirective } from './directives/file-drag-n-drop.directive';
import { ContainerGVDirective } from './directives/snappableContainer.directive';
import { AudioRecordingService } from './services/audio-recording.service';
import { VideoRecordingService } from './services/video-recording.service';
import { TimeDirective } from './directives/time.directive';

@NgModule({
  declarations: [
    ValidatorsComponent,
    FileDragNDropDirective,
    ContainerGVDirective,
    TimeDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
  ],
  providers: [
    UtilsService,
    FileDragNDropDirective,
    ContainerGVDirective,
    AudioRecordingService,
    VideoRecordingService
  ],
  exports: [
    FileDragNDropDirective,
    ContainerGVDirective,
    TimeDirective
  ]
})
export class SharedModule { }
