import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Constant } from '../providers/constant';
import { Location } from '@angular/common'
import { LocalStorageService } from './local-storage.service';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { WebsocketService } from './websocket.service';
@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  throttle = 300;
  commentthrottle = 10;
  scrollDistance = 1;
  scrollUpDistance = 2;
  maxPage = Constant.MAX_PAGINATIOn
  limit = Constant.LIMIT;
  updateNotification: () => void;
  notificationLength: number;
  followersLimit = Constant.FOLLOWERS_FOLLOWING_LIMIT;
  tabId: any = '';
  spinnerName = 'spinner1';
  unreadNotification = 0;
  @Output() changeEvent = new EventEmitter<boolean>();
  private isSubscribed = new Subject<any>(); //need to create a subject
  private badgeCountChange = new Subject<any>();
  private chatChatnge = new Subject<any>();
  private filterUpdate = new Subject<any>();
  private postUpdate = new Subject<any>();
  private editPostUpdate = new Subject<any>();
  private closedChatData = new Subject<any>();
  private postCompleteProgress = new Subject<any>();
  private storyCompleteProgress = new Subject<any>();
  private gymshotCompleteProgress = new Subject<any>();
  private gymshotVideoData = new Subject<any>();
  private storyData = new Subject<any>();
  private VideoFile = new Subject<any>();
  constructor(
    private toastr: ToastrService,
    public http: HttpClient,
    private spinner: NgxSpinnerService,
    private location: Location,
    private localstorege: LocalStorageService,
    public webSocketService: WebsocketService
  ) {
    this.notificationLength = 0;
  }

  back(): void {
    this.location.back()
  }

  showLoading() {
    this.spinner.show();
  }

  hideLoading() {
    this.spinner.hide();
  }

  showSuccessToast(msg) {
    this.toastr.success(msg);
  }

  showSuccessToastwithTitle(title, msg) {
    this.toastr.success(msg, title);
  }

  showErrorToast(msg) {
    this.toastr.error(msg);
  }

  showInfoToast(msg) {
    this.toastr.info(msg);
  }

  showWarningToast(msg) {
    this.toastr.warning(msg);
  }

  showInfoToastwithTitle(title, msg) {
    this.toastr.info(msg, title);
  }

  showWarningToastwithTitle(title, msg) {
    this.toastr.warning(msg, title);
  }

  showAlert(error) {
    this.showInfoToast(error);
  }

  changeDetect() {
    return this.changeEvent;
  }

  mustMatchs(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  getTime(time: any, format: any = Constant.TIME_FORMAT_TIMEPICKER) {
    const timeHours = moment(time, format).hours();
    const timeMinutes = moment(time, format).minutes();
    const dateTime = new Date();
    dateTime.setHours(timeHours);
    dateTime.setMinutes(timeMinutes);
    return dateTime;
  }

  getFormatedTime(time: any) {
    if (!isNaN(time)) {
      const timeHours = time.getHours();
      const timeMinues = time.getMinutes();
      const formatedTtime = `${timeHours}:${timeMinues}`;
      return formatedTtime;
    } else {
      return '';
    }
  }
  tConvert(time: any) {
    // Check correct time format and split into components
    if (time != '' && time != null) {
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(''); // return adjusted time or original string
    }

  }

  formatDate(dateString) {
    const date1 = moment(dateString, Constant.TIME_STAMP_FORMAT);
    const date2 = moment(moment(), Constant.TIME_STAMP_FORMAT);

    if (date2.diff(date1, 'days') > 8) {
      return moment(date1).format(Constant.POST_VIEW_FORMAT)
    } else if (date2.diff(date1, 'days') === 7) {
      return '1 week ago';
    } else if (date2.diff(date1, 'days') >= 2) {
      return `${date2.diff(date1, 'days')} days ago`;
    } else if (date2.diff(date1, 'days') >= 1) {
      return `1 day ago`;
    } else if (date2.diff(date1, 'hours') >= 2) {
      return `${date2.diff(date1, 'hours')} hours ago`;
    } else if (date2.diff(date1, 'hours') >= 1) {
      return `1 hour ago`;
    } else if (date2.diff(date1, 'minutes') >= 2) {
      return `${date2.diff(date1, 'minutes')} min ago`;
    } else if (date2.diff(date1, 'minutes') >= 1) {
      return `1 min ago`;
    } else if (date2.diff(date1, 'seconds') >= 3) {
      return `${date2.diff(date1, 'seconds')} sec ago`;
    } else {
      return 'now';
    }
  }

  log(message: any, values?: any) {
    if (!environment.production) {
      // console.log(message, values);
    }
  }

  countWords(str) {
    return str.trim().split(/\s+/).length;
  }

  sendUpdate(isSuscribeOrNot: boolean) {
    this.isSubscribed.next({ isSubscribe: isSuscribeOrNot });
  }

  getUpdate(): Observable<any> {
    return this.isSubscribed.asObservable();
  }

  sendBadgeUpdate(count: number) {
    this.badgeCountChange.next({ badgeCount: count });
  }

  getBadgeUpdate(): Observable<any> {
    return this.badgeCountChange.asObservable();
  }

  sendNotificatioUpdate(isNewNotification: boolean, type: any = null) {
    this.chatChatnge.next({ isNewNotification, type });
  }

  getNotificatioUpdate(): Observable<any> {
    return this.chatChatnge.asObservable();
  }

  sendFilterUpdate(filter: string, tab) {
    this.filterUpdate.next({ filter: filter, tabId: tab });
  }

  getFilterUpdate(): Observable<any> {
    return this.filterUpdate.asObservable();
  }

  sendPostUpdate(isRemove: boolean, postId) {
    this.postUpdate.next({ isRemove: isRemove, postId });
  }

  getPostUpdate(): Observable<any> {
    return this.postUpdate.asObservable();
  }

  sendEditPostUpdate(isEdit: boolean, postDetails) {
    this.editPostUpdate.next({ isEdit, postDetails });
  }

  getEditPostUpdate(): Observable<any> {
    return this.editPostUpdate.asObservable();
  }

  setClosedChatData(data: any) {
    this.closedChatData.next(data);
  }
  getClosedChatData() {
    return this.closedChatData.asObservable();
  }

  sendUpdateRequest(type: any) {
    this.chatChatnge.next({ type });
  }

  getUpdateRequest(): Observable<any> {
    return this.chatChatnge.asObservable();
  }
  setVideoFile(data) {
    this.VideoFile.next(data);
  }
  getVideoFile(): Observable<any> {
    return this.VideoFile.asObservable();
  }
  setStoryCompleteProgress(isComplete) {
    this.storyCompleteProgress.next(isComplete);
  }

  getStoryCompleteProgress(): Observable<any> {
    return this.storyCompleteProgress.asObservable();
  }
  setGymShotCompleteProgress(isComplete) {
    this.gymshotCompleteProgress.next(isComplete);
  }

  getGymShotCompleteProgress(): Observable<any> {
    return this.gymshotCompleteProgress.asObservable();
  }
  setStoryData(data: any) {
    this.storyData.next(data);
  }

  getStoryData(): Observable<any> {
    return this.storyData.asObservable();
  }
  setPostCompleteProgress(isComplete) {
    this.postCompleteProgress.next(isComplete);
  }

  getPostCompleteProgress(): Observable<any> {
    return this.postCompleteProgress.asObservable();
  }


  urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => {
      if (url.includes("/event/")) {
        const id = url.split("/event/").pop()
        return '<a href="/event-details/' + id + '">' + url + '</a>';
      } else {
        return '<a href="' + url + ' ' + '" target="_blank">' + url + '</a>';
      }
    })
  }

  eventUrlify(text) {
    try {
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      let id
      let eventText = text.replace(urlRegex, (url) => {
        if (url.includes("/event/")) {
          id = url.split("/event/").pop();
          return '';
        } else {
          return text;
        }
      })
      eventText = eventText.toLowerCase()
      let replaceText = 'click here to join'
      return eventText.replace(replaceText, '<br>' + '<a href="/event-details/' + id + '">' + replaceText + '</a>');
    } catch (err) {
      return text
    }
  }
  // thumbnail(videoFile){
  //   const canvas: any = document.createElement('canvas');
  //   const video: any = document.createElement('video');
  //   video.autoplay = true;
  //   video.muted = true;
  //   video.src = URL.createObjectURL(videoFile);
  //   video.name = videoFile.name;
  //   return new Promise((resolve) => {
  //     video.onloadeddata = async () => {
  //       const height = video.videoHeight;
  //       const width = video.videoWidth;
  //       canvas.width = height;
  //       canvas.height = width;
  //       await canvas
  //         .getContext("2d")
  //         .drawImage(video, 0, 0, width, height);

  //       canvas.toBlob(async (blob: any) => {
  //         const bufferMedia = new Uint8Array(await blob.arrayBuffer());
  //         const filename = video.name.split('.').slice(0, -1).join('.')
  //         resolve(new File([bufferMedia], filename, { type: 'image/png' }));
  //       });
  //     };
  //   });
  // }

  async thumbnail(videoFile) {
    const video: any = document.createElement('video');
    const canvas: any = document.createElement('canvas');
    video.style.display = 'none';
    canvas.style.display = 'none';
    video.autoplay = true;
    video.muted = true;
    video.name = videoFile.name;
    // Trigger video load
    await new Promise<void>((resolve, reject) => {
      video.addEventListener('loadedmetadata', () => {
        video.width = video.videoWidth;
        video.height = video.videoHeight;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        // Seek the video to 25%
        video.currentTime = video.duration * 0.25;
      });
      video.addEventListener('seeked', () => resolve());
      video.src = URL.createObjectURL(videoFile);;
    });

    // Draw the thumbnailz
    canvas.getContext('2d')
      .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    const imageUrl = canvas.toDataURL('image/png');

    return canvas;
  }

  gettingWidth(height, width) {
    if (height === width && height <= 300) {
      return 320;
    } else if (height === width && height > 300 && height <= 550) {
      return 480;
    } else if (height === width && height > 550 && height <= 700) {
      return 640;
    } else if (height === width && height > 700 && height <= 1000) {
      return 720;
    } else if (height === width && height > 1000) {
      return 1080;
    }
    else if (height < width && width <= 300) {
      return 320;
    } else if (height < width && width > 300 && width <= 550) {
      return 480;
    } else if (height < width && width > 550 && width <= 700) {
      return 640;
    } else if (height < width && width > 700 && width <= 1000) {
      return 720;
    } else if (height < width && width > 1000) {
      return 1080;

    } else if (height > width && height <= 300) {
      return (width / height) * 320;
    } else if (height > width && height > 300 && height <= 550) {
      return (width / height) * 480;
    } else if (height > width && height > 550 && height <= 700) {
      return (width / height) * 640;
    } else if (height > width && height > 700 && height <= 1000) {
      return (width / height) * 720;
    } else if (height > width && height > 1000) {
      return (width / height) * 1080;
    }
    return 320;
  }

  gettingHeight(height, width) {
    if (height === width && height <= 300) {
      return 320;
    } else if (height === width && height > 300 && height <= 550) {
      return 480;
    } else if (height === width && height > 550 && height <= 700) {
      return 640;
    } else if (height === width && height > 700 && height <= 1000) {
      return 720;
    } else if (height === width && height > 1000) {
      return 1080;

    } else if (height < width && width <= 300) {
      return (height / width) * 320;
    } else if (height < width && width > 300 && width <= 550) {
      return (height / width) * 480;
    } else if (height < width && width > 550 && width <= 700) {
      return (height / width) * 640;
    } else if (height < width && width > 700 && width <= 1000) {
      return (height / width) * 720;
    } else if (height < width && width > 1000) {
      return (height / width) * 1080;

    } else if (height > width && height <= 300) {
      return 320;
    } else if (height > width && height > 300 && height <= 550) {
      return 480;
    } else if (height > width && height > 550 && height <= 700) {
      return 640;
    } else if (height > width && height > 700 && height <= 1000) {
      return 720;
    } else if (height > width && height > 1000) {
      return 1080;
    }
    return 320;
  }

  async compressImage(file, isProfilePic = false) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          // console.log('Actual Width and Height', img.naturalWidth, img.naturalHeight);
          const canvas: any = document.createElement('canvas');
          let height;
          let width;
          if (isProfilePic) {
            height = 300;
            width = 300;
          } else {
            height = Math.round(this.gettingHeight(img.naturalHeight, img.naturalWidth));
            width = Math.round(this.gettingWidth(img.naturalHeight, img.naturalWidth));
          }
          img.height = height;
          img.width = width;
          canvas.width = width;
          canvas.height = height;
          canvas.getContext('2d')
            .drawImage(img, 0, 0, width, height);
          canvas.toDataURL()
          // return resolve(this.dataURItoBlob(img.src));
          const newFile = this.convertToBlob(canvas, file)
          return resolve(newFile);
        };
      };
    });
  }

  async convertToBlob(file, f) {
    return new Promise((resolve, reject) => {
      /* Old Code Without Quality*/
      //   file.toBlob(async (blob: any) => {
      //     const bufferMedia = new Uint8Array(await blob.arrayBuffer());
      //     const filename = f.name.split('.').slice(0, -1).join('.')
      //     const imageFile = await new File([bufferMedia], filename, { type: f.type  });
      //     return resolve(imageFile);
      //   });
      // });

      /* New Code With Quality*/
      file.toBlob(async (blob: any) => {
        const bufferMedia = new Uint8Array(await blob.arrayBuffer());
        const filename = f.name.split('.').slice(0, -1).join('.')
        const imageFile = await new File([bufferMedia], filename, { type: f.type });

        return resolve(imageFile);
      }, f.type, 0.75);
    });
  }

  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    //New Code
    return new Blob([ab], { type: mimeString });
  }

  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let date = new Date()
    filename = date.getTime() + filename
    return new File([u8arr], filename, { type: mime });
  }
}
