export class Constant {
	public static readonly TIME_STAMP_FORMAT = "YYYY-MM-DD HH:mm:ss";
	public static readonly DATE_FORMAT = "YYYY-MM-DD";
	public static readonly CHAT_FORMAT = "MMM D YYYY"
	public static readonly TIME_FORMAT = "HH:mm:ss";
	public static readonly TIME_FORMAT_AM_PM = "hh:mm A";
	public static readonly TIME_FORMAT_HH_MM = "HH:mm";
	public static readonly CALENDER_VIEW_FORMAT = "DD MMM YYYY";
	public static readonly DATE_FORMAT_EVENT = "DD-MM-YYYY";
	public static readonly POST_VIEW_FORMAT = "DD MMMM";
	public static readonly TIME_FORMAT_TIMEPICKER = 'hh:mm';
	public static readonly LIMIT = 10;
	public static readonly CHAT_LIST_LIMIT = 30;
	public static readonly NOTIFICATION_LIMIT = 5;
	public static readonly USER_LIMIT = 6;
	public static readonly FOLLOWERS_FOLLOWING_LIMIT = 12;
	public static readonly EVENT_COMMENT_LIMIT = 15;
	public static readonly MAX_PAGINATIOn = 10
	public static readonly USER_API_ROUTES = {
		REGISTER: "/um/v1/user/register",
		GET_CATEGORY: "/um/v1/category/get-category",
		VERIFY_EMAIL: "/um/v1/user/verify-otp",
		LOGIN: "/um/v1/user/login",
		SEND_OTP: "/um/v1/user/send-otp",
		SAVE_CATEGORY: "/um/v1/category/save-user-category",
		RESET_PASSWORD: "/um/v1/user/reset-password",
		CHANGE_PASSWORD: "/um/v1/user/change-password",
		SOCIAL_LOGIN: "/um/v1/user/social-signup",
		CHECk_SOCIAL_LOGIN: "/um/v1/user/check-social",
		CHECK_APPLE_DATA: "/um/v1/user/check-apple-data",
		SOCIAL_SIGNUP: "/um/v1/user/social-signup",
		GET_PROFILE: "/um/v1/user/profile",
		EDIT_PROFILE: "/um/v1/user/edit-profile",
		LOGOUT: "/um/v1/user/logout",
		CONTACT_US: "/um/v1/user/contact-us",
		USER_CATEGORIES: "/um/v1/category/user-categories",
		UPLOAD: "/um/v1/upload/image-upload",
		SETTING: "/um/v1/user/setting",
		GET_SETTING: "/um/v1/user/get-setting",
		LOAD_COMMENTS: "/um/v1/post/comments",
		ADD_COMMENTS: "/nm/v1/post/comment",
		ADD_REMOVE_BOOKMARK: "/um/v1/post/add-remove-bookmark",
		BOOKMARKLIST: "/um/v1/post/bookmarks",
		HIDE_POST: "/um/v1/post/hide",
		REPORT_POST: "/um/v1/post/report",
		REMOVE_BOOKMARK: "/um/v1/post/remove-bookmark",
		NOTiFICATION: "/um/v1/user/get-notification",
		READ_NOTiFICATION: "/um/v1/user/read-notification",
		READ_NOTiFICATION_ALL: "/um/v1/user/read-notification-count",
		PROFILE_DATA: "/um/v1/user/qr-user-data",
		UPDATE_FCM_TOKEN: "/um/v1/user/update-fcm-token",
		EVENTLIST: "/um/v1/event/get",
		EVENT_SUGGESTION: "/um/v1/event/suggestions",
		OTHER_USER_EVENTLIST: "/um/v1/event/other-user",
		CREATEEVENT: "/nm/v1/events/create",
		EDITEVENT: "/nm/v1/event/create",
		DELETEEVENT: "/nm/v1/event/delete",
		ATTENDEVENT: "/nm/v1/event/attend",
		EVENTDETAILS: "/um/v1/event/details",
		EVENT_START: "/nm/v1/event/start",
		EVENT_END: "/nm/v1/event/end",
		EVENT_LEAVE: "/nm/v1/event/leave",
		GET_AGORA_TOKEN: "/um/v1/event/get-agora-token",
		TAG_FRIEND_SEARCH: "/nm/v1/follow/search",
		JOIN_EVENT_PARTICIPANTS: "/nm/v1/event/join",
		SHAREEVENT: "/nm/v1/event/share",
		START_RECORDING: "/um/v1/event/start-recording",
		STOP_RECORDING: "/um/v1/event/end-recording",
		COUNTRY_LIST: "/um/v1/user/country",
		LANGUAGE_LIST: "/um/v1/user/languages",
		STATE_LIST: "/um/v1/user/states",
		CMS: "/um/v1/user/cms",
		FAQ: "/um/v1/user/faq",
		DELETE_USER_DATA: "/um/v1/user/delete-user-data",
		DELETE_ACCOUNT: "/um/v1/user/delete-account",
		GET_REPORT_LIST: "/um/v1/gymshot/get-report-question-list",
		UPLOAD_VERIFY_DOCUMENT: "/um/v1/user/upload-image-verification",
		GET_VERIFY_DOCUMENT: "/um/v1/user/verification",
		GET_SPOTIFY_URL: "/um/v1/spotify/login",
		GET_SPOTIFY_TOKEN: "/um/v1/spotify/get-access-token",
		GET_SPOTIFY_DELINK: "/um/v1/spotify/delink",
		GET_SPOTIFY_PLAYLIST: "/um/v1/spotify/get-playList",
		GET_SPOTIFY_PLAYLIST_PLAY: "/um/v1/spotify/get-player-play",
		GET_SPOTIFY_PLAYLIST_SHUFFLE: "/um/v1/spotify/get-player-shuffle",
		GET_SPOTIFY_PLAYLIST_REPEAT: "/um/v1/spotify/get-player-repeat",
		EVENT_COMMENT_DATA: "/um/v1/event/get-comments",
		GET_HEALTH_RECORD: "/um/v1/health/get-health-record"
	};

	public static readonly PAYMENT_API_ROUTES = {
		GET_CARDS: "/pm/v1/card/get-card",
		ADD_CARD: "/pm/v1/card/add-card",
		EDIT_CARD: "/pm/v1/card/update-card",
		SET_PRIMARY_CARD: "/pm/v1/card/set-primary-card",
		DELETE_CARD: "/pm/v1/card/delete-card",
		CARD_DETAILS: "/pm/v1/card/get-card-detail",
		GET_BANKS: "/pm/v1/bank/get-bank",
		SET_PRIMARY_BANK: "/pm/v1/bank/set-primary-bank",
		ADD_BANK: "/pm/v1/bank/add-bank",
		EDIT_BANK: "/pm/v1/bank/update-bank",
		DELETE_BANK: "/pm/v1/bank/delete-bank",
		GET_WALLET: "/pm/v1/wallet/get-wallet",
		ADD_BALANACE: "/pm/v1/wallet/add-wallet-balance",
		SUCCESS_PAYMENT: "/pm/v1/wallet/payment-success",
		WALLET_SETTINGS: "/pm/v1/wallet/get-wallet-setting",
		WITHDRAW: "/pm/v1/wallet/withdrawBalance",
		DOWNLOAD_REPORT: "/um/v1/user/transaction-report"
	};
	public static readonly STORY_API_ROUTES = {
		GET_STORY_LIST: "/um/v1/story/get-user-story",
		GET_STORY_BY_ID: "/um/v1/story/get-story-id",
		POST_GYMSHOTS: "/um/v1/gymshot/get-gymshot",
		REPORT_GYMSHOTS: "/nm/v1/gymshot/report",
		REPORT_GYMPOST: "/nm/v1/post/report",
		GET_GYMSHOTS_LIKE_LIST: "/um/v1/gymshot/get-likes",
		GET_STORY_LISTS: "/um/v1/story/get-story",
		POST_STORY_ADD: "/nm/v1/story/add-story",
		POST_GYMSHOT_ADD: "/nm/v1/gymshot/add-gymshot",
		POST_STORY_SHARE: "/nm/v1/story/story-share",
		POST_STORY_UPDATE: "/um/v1/story/update-story",
		IMAGE_UPLOAD: "/um/v1/story/upload-image-story",
		VIDEO_UPLOAD: "/um/v1/post/upload-video",
		AUDIO_UPLOAD: "/um/v1/story/upload-audio-story",
		TAG_STORY: "/nm/v1/story/tag-story",
		WHO_VIEW_MYSTORY: "/um/v1/story/get-user-list-view-story",
		STORY_COMMENT: "/nm/v1/story/story-comment",
		STORY_REACT: "/nm/v1/story/story-like",
		UPDATE_VIEW_STORY: "/um/v1/story/view-story",
		STORY_DELETE: "/um/v1/story/remove-story",
		GYMSORT_AUDIO_UPLOAD: "/um/v1/gymshot/upload-audio-gymshot",
		DELETE_GYMSHOT: "/nm/v1/gymshot/remove-gymshot",
		GET_GYMSHOTS_COMMENT_LIST: "/um/v1/gymshot/get-comments",
		GET_GYMSHOTS_DETAILS: "/um/v1/gymshot/get-gymshot-details",
		SHARE_SHOT: "/nm/v1/gymshot/share-gymshot",
		SHARE_AS_STORY: "/nm/v1/gymshot/add-gymshot-story",
		COMMENT_GYMSHOT: "/nm/v1/gymshot/add-comment-gymshot",
		EDIT_GYMSHOT: "/nm/v1/gymshot/update-gymshot",
		REPORT_STORY: "/nm/v1/story/report",
		DELETE_COMMENT: "/nm/v1/post/delete-post-comment",
		DELETE_GYMSHOT_COMMENT: "/nm/v1/gymshot/delete-comment-gymshot"
	};
	public static readonly GYM_PLAN_API_ROUTES = {
		GET_GYM_PLAN: "/um/v1/gym-plan/get-gym-plan",
		ADD_GYM_PLAN: "/um/v1/gym-plan/add-gym-plan",
		EDIT_GYM_PLAN: "/um/v1/gym-plan/update-gym-plan",
		DELETE_GYM_PLAN: "/um/v1/gym-plan/delete-gym-plan",
		GET_PROMOTION: "/um/v1/promotion/list",
		DURATION_DISCOUNT: "/um/v1/promotion/get-duration-and-discount",
		ADD_PROMOTION: "/um/v1/promotion/create",
		GET_ALL_PLAN: "/um/v1/gym-plan/all-gym-plan",
		ONT_TO_ONE: "/um/v1/gym-plan/one-to-one",
		GET_MEMBERSHIP_PLAN: "/um/v1/gym-plan/get-gym-plan",
		GET_WORKOUT_LIST: "/nm/v1/workout/get-workout_list",
		GET_PURCHASED_ALL_PLAN: "/um/v1/gym-plan/purchased-both-plan",
		GET_PURCHASED_ONE_TO_ONE: "/um/v1/gym-plan/user-purchased-one-to-one",
		GET_PURCHASED_MEMBERSHIP_PLAN: "/um/v1/gym-plan/membership",
		GET_PURCHASED_WORKOUT: "/nm/v1/workout/fetch-my-workout-list",
		ADD_ONE_TO_ONE: "/um/v1/gym-plan/add-one-to-one-gym-plan",
		EDIT_ONT_TO_ONT: "/um/v1/gym-plan/edit-one-to-one-plan",
		ADD_EDIT_WORKOUT: "/nm/v1/workout/add-workout",
		DELETE_ONE_TO_ONE: "/um/v1/gym-plan/delete-gym-plan",
		GET_EXERCISE: '/nm/v1/exercise/get-exercise-list-cc',
		GET_EXERCISE_BY_ID: '/nm/v1/exercise/get-exercise-by-id',
		GET_PR_FOCUS_LIST: '/nm/v1/exercise/get-pr-focus-list',
		ADD_EXERCISE: '/nm/v1/exercise/add-exercise-by-cc',
		UPDATE_EXERCISE: '/nm/v1/exercise/update-exercise-cc',
		UPLOAD_EXE_IMAGE: '/nm/v1/exercise/upload-image-cc',
		UPLOAD_THUMB_IMAGE: '/nm/v1/exercise/upload-thumbnail-cc',
		DELETE_EXERCISE: '/nm/v1/exercise/delete-exercise-cc',
		DELETE_WORKOUT: "/nm/v1/workout/delete-workout",
		GET_WORKOUT_CC: "/nm/v1/workout/get-workout",
		DELETE_SECTION: "/nm/v1/workout/delete-section-from-workout",
		DELETE_EXERCISE_WORKOUT: "/nm/v1/workout/delete-exercise-from-workout",
		VIEW_WORKOUT: "/nm/v1/workout/fetch-my-workout",
		UPDATE_WORKOUT: "/nm/v1/workout/update-exercise-watch"
	};

	public static readonly GYM_FANS_ALBUM = {
		GET_ALL_ALBUM: "/um/v1/album/get-album",
		CREATE_ALBUM: '/um/v1/album/add-album',
		UPLOAD_Album_IMAGES: "/um/v1/album/upload-image-album",
		GET_SINGLE_ALBUM_DETAILS: "/um/v1/album/get-album-id",
		DELETE_ALBUME: "/um/v1/album/delete-image-video"
	};

	public static readonly GYM_FANS_SUPPORT_TICKET = {
		GET_ALL_SUPPORT_TICKET: "/um/v1/ticket/get-ticket",
		GET_TICKET_DETAILS: "/um/v1/ticket/get-ticket-id",
		GET_TICKET_CATEGORY: "/um/v1/ticket/get-ticket-category",
		GET_SUB_TICKET_CATEGORY: "/um/v1/ticket/get-sub-ticket-category",
		CREATE_TICKET: "/um/v1/ticket/add-ticket",
		UPLOAD_TICKET_IMAGES: "/um/v1/ticket/upload-image-ticket"
	};

	public static readonly FAN_CHANNEL_ROUTES = {
		GET_FAN_CHANNEL_USER_LIST: "/um/v1/fanchannel/get-user-list",
		GET_ALL_FAN_CHANNEL_LIST: "/um/v1/fanchannel/get-fanchannel-list",
		CREATE_FAN_CHANNEL: "/nm/v1/fanchannel/add-fanchannel",
		EDIT_FAN_CHANNEL: "/nm/v1/fanchannel/edit-fanchannel",
		UPLOAD_FAN_CHANNEL_PROFILE: "/um/v1/fanchannel/upload-image-fanprofile",
		UPLOAD_IMAGE_FAN_MESSAGE: "/um/v1/fanchannel/upload-image-fanmessage",
		GET_FAN_CHANNEL_ADDED_USER_LIST: "/um/v1/fanchannel/get-user-list-add-fanchannel",
		REMOVE_FAN_CHANNEL_MEMBER: "/nm/v1/fanchannel/delete-user-fanchannel",
		DELETE_FAN_CHANNEL: "/nm/v1/fanchannel/remove-fanchannel",
		GET_FAN_CHANNEL_CHAT_HISTORY: "/um/v1/fanchannel/get-fanchannel-history",
		ADD_USER_IN_FAN_CHANNEL: "/nm/v1/fanchannel/add-user-fanchannel",
		READ_MESSAGE_FAN_CHANNEL: "/nm/v1/fanchannel/read"
	};

	public static readonly POTS_API_ROUTES = {
		IMAGE_UPLOAD: "/um/v1/post/upload-image",
		THUMB_UPLOAD: "/um/v1/post/upload-thumbnail",
		VIDEO_UPLOAD: "/um/v1/post/upload-video",
		AUDIO_UPLOAD: "/um/v1/post/upload-audio",
		ADD_POST: "/nm/v1/post/add-post",
		GET_POST: "/um/v1/post/get-post",
		POST_DETAILS: "/um/v1/post/get-post-details",
		SCHEDULE_POST: "/um/v1/post/schedule-post",
		BLOCK_USER: "/um/v1/user/block-unblock",
		POST_TIPS: "/nm/v1/post/add-tips",
		POST_TIPS_LIST: "/um/v1/post/get-tips",
		LIKE_UNLIKE: "/nm/v1/post/like-unlike",
		LIKE_LIST: "/um/v1/post/likes",
		DELETE: "/nm/v1/post/delete",
		CHAT_IMAGE: "/nm/v1/chat/health-share",
	}

	public static readonly CHAT_API = {
		CHAT_LIST: "/nm/v1/chat/get-list",
		CHAT_HISTORY: "/nm/v1/chat/get-history",
		DELETE_HISTORY: "/nm/v1/chat/delete",
		READ_CHAT: "/nm/v1/chat/read",
		LIKE_GYMSHOTS: "/nm/v1/gymshot/add-like-gymshot",
		FAN_CHANNEL_CHAT_HISTORY: "/um/v1/fanchannel/get-fanchannel-history",
	}

	public static readonly SOCKET_EVENTS = {
		ADD_LIKE: "addLike",
		ADD_COMMENT: "addComment",
		ADD_POST: "addPost",
		ADD_STORY: "addStory",
		ADD_GYMSHOT: "addGymshot",
		EDIT_POST: "editPost",
		DELETE_POST: "deletePost",
		JOIN_ROOM: "joinRoom",
		UNREAD_COUNT_GROUP: "unreadCountGroup",
		RECEIVE_MESSAGE_OF_GROUP: "receiverMessageOfGroup",
		ADD_ALBUM: "addAlbum",
		CONNECT: "connection",
		DISCONNECT: "disconnect",
		ON_MESSAGE: "onNewMessage",
		RECEIVE_MESSAGE: 'receiverMessage',
		ADD_FAN_CHANNEL_MESSAGE: 'addFanChannelMessage',
		ON_NEW_MESSAGE_OF_GROUP: "onNewMessageOfGroup",
		CHAT_SOCKET: 'chatSocket',
		UPDATE_STATUS: 'updateStatus',
		BLOCK_EVENT: 'blockEvent',
		EVENT_JOIN_ROOM: "joinEventButtonEnable",
		EVENT_START_ENABLE: "enableStartEvent",
		EVENT_START: "enableStartEventApp",
		JOIN_EVENT: "joinLiveEvent",
		JOIN_PARTICIPANT: "joinedByParticipant",
		END_EVENT: "endEvent",
		GET_USER_STATUS: "getUserStatus",
		USER_DELETED: "userDeleted",
		POST_UPLOADED: 'postUploaded',
		DELETE_GYMSHOT: "deleteGymshot",
		LIKE_GYMSHOT: "likeGymshot",
		GYMSHOT_COMMENT: "addCommentGymshot",
		EDIT_SHOT: "editGymshot",
		EVENT_ADD_COMMENT: "addCommentEvent",
		DELETE_POST_COMMENT: "deletePostComment",
		DELETE_GYMSHOT_COMMENT: "deleteGymShotComment",
		EDIT_COMMENT: "editComment",
		EDIT_GYMSHOT_COMMENT: "editGymshotComment",
	}

	public static readonly SEARCH_API_ROUTES = {
		POPULAR_CONTENT_CREATOR: "/um/v1/post/popular-content-creator",
		SEARCH: "/um/v1/user/search"
	}

	public static readonly FOLLOW_API_ROUTES = {
		FOLLOWING: "/nm/v1/follow/following",
		FOLLOWERS: "/nm/v1/follow/followers",
		FOLLOW_UNFOLLOW: "/nm/v1/follow/follow-unfollow",
		ACCEPT_REJECT: "/nm/v1/follow/accept-reject-request",
		SUBSCRIPTIONLIST: "/um/v1/user/subscription-list",
		SUBSCRIBEDCREATORLIST: "/um/v1/user/subscribed-list",
		SUBSCRIBEUNSUSBCRIBE: "/nm/v1/gym-plan/subscribe-unsubscribe-plan",
		PEOPLE_YOU_MAY_KNOW: "/um/v1/user/people-you-may-know",
		FRIEND_REQUESTS: "/um/v1/user/friend-request"
	}

	public static readonly OTHER_USER_API_ROUTES = {
		GET_PROFILE: "/um/v1/user/user-profile",
		GETBLOCKUSERLIST: "/um/v1/user/block-details",
		GYM_PLAN_LIST: "/um/v1/gym-plan/other-user-plan-list",
		AUTO_RENEW: "/nm/v1/gym-plan/update-auto-renew",
		CONFIRM_PLAN: "/nm/v1/gym-plan/subscribe-unsubscribe-plan",
		CREATOR_LIST: "/um/v1/user/content-creator-list",
		VIEW_ADVERTISEMENT: "/um/v1/post/update-ad-views",
		PURCHASE_ONE_TO_ONE: "/nm/v1/gym-plan/purchase-one-to-one",
		PURCHASE_WORKOUT: "/nm/v1/workout/purchase-workout"
	}

	public static readonly USER_DETAILS_TAB = {
		FOLLOWING: "Followings",
		FOLLOWERS: "Followers",
		SUBCRIPTIONS: "Subscription",
		SUBSCRIBED_CREATOR: "Subscribed Creators"
	}

	public static readonly DEVICE_TYPE = {
		WEB: "web",
		ANDROID: "android",
		IOS: "ios",
	};

	public static readonly USER_TYPE = {
		REGULAR_USER: "regularUser",
		CONTENT_CREATOR: "contentUser",
	};

	public static readonly MODEL_TYPE = {
		VERIFY_EMAIL: "verify-email",
		FORGOT_PASSWORD: "forgot-password",
		CHANGE_PASSWORD: "change-password",
	};

	public static readonly SOCIAL_TYPE = {
		GMAIL: "google",
		DIRECT_USER: "directUser",
		FACEBOOK: "facebook",
		APPLE: "apple",
	};

	public static readonly UPLOAD_TYPE = {
		IMAGE: "image",
		IMAGE_JPEG: "image/jpeg",
		IMAGE_JPG: "image/jpg",
		VIDEO: "video/mp4",
		AUDIO: "audio",
		VIDEO_TYPE: "video"
	};

	public static readonly LOCAL_STORAGE_KEY = {
		PROFILE_TAB: 'profileTabId',
		USER_DETAILS: 'userDetails',
		TOKEN: 'token',
		WALLATE_TAB: 'walletTab',
		CHAT_REDIRECT_DATA: 'chatRedirectData'
	}

	public static readonly PROFILE_TAB = {
		TIMELINE: 'timeline',
		PAYMENT_OPTION: 'payment-option',
		BASIC_INFO: 'basic-info',
		EVENt_LIST: 'event-list',
		GYM_PLANS: 'gym-palns',
		CREATE_ALBUM: 'create-album'
	}

	public static readonly STRIPE_CARD_OPTION = {
		hidePostalCode: true,
		style: {
			base: {
				iconColor: "#666EE8",
				backgroundColor: "#fff",
				color: "#495057",
				fontWeight: "400",
				fontFamily: '"Poppins", sans-serif',
				fontSize: "16px",
				"::placeholder": {
					color: "#495057",
				},
			},
		},
	};

	public static readonly STRIPE_CARD_CVC_OPTION = {
		style: {
			base: {
				backgroundColor: "#fff",
				color: "#495057",
				fontWeight: "400",
				fontFamily: '"Poppins", sans-serif',
				fontSize: "16px",
				"::placeholder": {
					color: "#A2A2A2",
				},
			},

		},
	};

	public static readonly STRIPE_CARD_EXPIRY_OPTION = {
		style: {
			base: {
				color: "#495057",
				backgroundColor: "#fff",
				fontWeight: "400",
				fontFamily: '"Poppins", sans-serif',
				fontSize: "16px",
				"::placeholder": {
					color: "#A2A2A2",
				},
			},
		},
	};

	public static readonly STRIPE_CARD_NUMBER_OPTION = {
		style: {
			base: {
				color: "#495057",
				fontWeight: "400",
				fontFamily: '"Poppins", sans-serif',
				fontSize: "16px",
				"::placeholder": {
					color: "#A2A2A2",
				},
			},
		},
		showIcon: true,
	};

	public static readonly STRIPE_ELEMENT_OPTION = {
		locale: "auto",
		fonts: [
			{
				family: '"Poppins", sans-serif',
				src: "url(https://fonts.googleapis.com/css2?family=Poppins&display=swap)",
				style: "normal",
				weight: "400",
				display: "swap",
			},
		],
	};

	public static readonly NOTIFICATION_TYPE = {
		SCHEDULE_POST: "schedulePost",
		LIKE_POST: "likePost",
		COMMENT_POST: "commentPost",
		ADD_POST: "addPost",
		EVENT: "event",
		SENT_REQUEST: "sentRequest",
		START_FOLLOWING: "startFollowing",
		APPROVE_REQUEST: "approveRequest",
		PAYMENT: "payment"
	}

	public static readonly TRANSACTION_TYPE = {
		CREDIT: "Credit",
		DEBIT: "Debit",
		EARNING: "Earning",
		WITHDRAW: "Withdraw",
		ALL: "All"
	}

	public static readonly WALLET_TAB_TYPE = {
		CREDIT: "Credit",
		DEBIT: "Deduction",
		EARNING: "Earning",
		WITHDRAW: "Withdrawals",
		ALL: "All"
	}

	public static readonly DATE_FILTER = {
		TODAY: "today",
		YESTERDAY: "yesterday",
		DAYS_7: "7days",
		DAYA_15: "15days",
		DAYS_30: "30days"
	}

	public static readonly WITHDRAW_STATUS = {
		SUCCESS: "Success",
		FAILED: "Failed",
		PENDING: "Pending"
	}

	public static readonly MEDIA_TYPE = {
		VIDEO: 'video',
		AUDIO: 'audio',
	};

	public static readonly LIVE_STREAM_EVENTS = {
		USER_PUBLISHED: 'user-published',
		USER_UNPUBLISHED: 'user-unpublished',
	};

	public static readonly UPDATE_SENT_TYPE = {
		FRIEND_REQUEST: "friendRequest",
		FOLLOW_UNFOLLOW: "followUnfollow",
		READ_MESSAGE: "readMessage",
		ADD_EDIT_POST: "addEditPost"
	}

	public static readonly DROP_DOWN_SETTINGS = {
		singleSelection: false,
		idField: 'id',
		textField: 'name',
		selectAllText: 'Select All',
		unSelectAllText: 'UnSelect All',
		itemsShowLimit: 3,
		allowSearchFilter: true
	};

	public static readonly CMS_TYPE = {
		ABOUT_US: 'about',
		PAYMENT_POLICY: 'payment-policy',
		PRIVACY_POLICY: 'privacy-policy',
		CREATOR_PORTAL: 'creator-portal',
		TNC: 'tnc'
	}
}
