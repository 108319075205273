import { Injectable } from '@angular/core';
import { io } from "socket.io-client";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';
import { Constant } from '../providers/constant';

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {

    socket: any;
    readonly url: string = environment.WEBSOCKET_URL;

    constructor(
        private localStorage: LocalStorageService
    ) {
        this.connect();
    }

    connect() {
        const token: any = this.localStorage.getLocalStore(Constant.LOCAL_STORAGE_KEY.TOKEN);
        if (token !== null) {
            // this.socket = io(`${this.url}?authorization=${token}&transport=websocket`);
            this.socket = io(this.url, {
                query: {
                    authorization: token
                },
                path: "/socket.io",
                transports: ["websocket"],
                autoConnect: true
            })
            // console.log(this.socket);
        }
    }

    disconnect() {
        this.socket.on("disconnect", (data) => {
            // console.log('socket disconnect', data); // false
        });
    }

    reconnect() {
        this.socket.io.on("reconnect", () => {
            // console.log(this.socket); // false
        });
    }

    listen(eventName: string) {
        // console.log('this.socket-------------')
        // console.log('this.socket.connected------------', this.socket.connected)
        return new Observable((subscriber) => {
            if (this.socket !== undefined && this.socket.connected) {
                this.socket.on(eventName, (data) => {
                    subscriber.next(data);
                });
            }
        });
    }

    emit(eventName: string, data: any) {
        if (this.socket !== undefined && this.socket.connected) {
            this.socket.emit(eventName, data);
        }
    }

    stopListen(eventName: string) {
        this.socket.removeAllListeners(eventName);
    }
}
