<div class="event_detail_body_section" [ngClass]="{ 'modal-overflow': isExpand }" *ngIf="eventData">
    <div #screen class="left_Section" style="backdrop-filter: blur(8px)">
        <!-- <button *ngIf="eventData.videoUrl" type="button"
            class="pull-right audio-button" (click)="muteVideo()">
            <img *ngIf="!videoAudioEnabled" src="../../../../assets/img/live/sound.svg" alt="">
            <img *ngIf="videoAudioEnabled" src="../../../../assets/img/live/mute-sound.svg" alt="">
        </button>
        <button *ngIf="eventData.videoUrl"
             id="playPauseButton" class="button" (click)="playPause()">
            <img *ngIf="isVideoPause" src="../../../../../assets/img/play_icon.svg">
        </button> -->
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeModel()">
            <img src="../../../../assets/img/modal_close.svg">
        </button>
        <div class="full_post_carousel_section" *ngIf="eventData.videoUrl === null">
            <div class="img_box">
                <!-- <img src="{{eventData?.eventImage}}" alt=""> -->
                <div class="px-3" *ngIf="eventData.videoUrl === null"
                    [ngClass]="{ 'user_post_img_lock_section': eventData.isSubscriberOnly}">
                    <img *ngIf="eventData.videoUrl === null"
                        src="{{eventData.preSignedEventImageUrls ? eventData.preSignedEventImageUrls : '../../../../../assets/img/gym1.jpeg'}}">
                    <div class="img_lock_icon cursor-pointer" (click)="subscribeFirst()"
                        *ngIf="eventData.isSubscriberOnly">
                        <img src="../../../../assets/img/lock_icon.svg">
                    </div>
                </div>
            </div>
            <!-- <label style="color: #FFFFFF">Image </label> -->
        </div>
        <div *ngIf="eventData.videoUrl !== null">
            <div class="px-3"
                    [ngClass]="{ 'user_post_img_lock_section': eventData.isSubscriberOnly}">
                    <video id="myVideo" class="video-js video-dimension"  controls
                    [ngClass]="{ 'blur_effect': eventData.isSubscriberOnly }"
                        controlsList="nodownload" style="z-index: 999999;">
                        <source [src]="eventData.videoUrl" type="video/mp4">
                        <source [src]="eventData.videoUrl" type="video/ogg">
                        <source [src]="eventData.videoUrl" type="video/webm">
                    </video>
                    <div class="img_lock_icon cursor-pointer" (click)="subscribeFirst()"
                        *ngIf="eventData.isSubscriberOnly" style="z-index: 999999;">
                        <img src="../../../../assets/img/lock_icon.svg">
                    </div>
                </div>
            <!-- <label style="color: #FFFFFF">Image </label> -->
        </div>
    </div>
    <div class="right_Section">
        <div class="feed_header_Section">
            <label>{{eventData?.name}}</label>
            <ul>
                <a>
                    <li class="cursor-pointer" (click)="common.redirectToDetailsUrl(eventData)">
                        <img src="../../../../../assets/img/user.svg">
                        {{eventData?.creatorDetails?.firstName | titlecase }} {{eventData?.creatorDetails?.lastName |
                        titlecase }}
                        <span *ngIf="eventData?.creatorDetails?.status == 'Approved'">
                            <svg width="15" height="15" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.997" fill-rule="evenodd" clip-rule="evenodd" d="M245 0C254 0 263 0 272 0C287.83 3.32813 300.33 11.6615 309.5 25C312.901 31.8814 315.901 38.8814 318.5 46C330.651 59.0019 345.484 64.5019 363 62.5C385.314 50.4999 406.981 51.4999 428 65.5C434.167 71.6667 440.333 77.8333 446.5 84C459.362 105.383 460.362 127.383 449.5 150C447.959 166.717 453.125 180.884 465 192.5C490.332 198.997 505.999 214.831 512 240C512 250.333 512 260.667 512 271C507.151 291.355 495.151 305.855 476 314.5C467.027 316.796 458.027 318.796 449 320.5C433.951 333.382 433.451 346.548 447.5 360C461.412 383.29 460.746 406.29 445.5 429C437.786 438.391 428.619 445.891 418 451.5C398.314 458.127 378.981 457.127 360 448.5C343.609 446.185 330.109 451.352 319.5 464C311.984 489.018 295.817 505.018 271 512C260.667 512 250.333 512 240 512C223.099 507.932 209.932 498.598 200.5 484C197.77 477.809 195.437 471.476 193.5 465C176.993 446.746 157.826 443.246 136 454.5C116.864 459.811 99.1968 456.811 83 445.5C77.5 440 72 434.5 66.5 429C56.2225 414.957 52.5558 399.291 55.5 382C57.0432 375.37 59.3765 369.037 62.5 363C65.7602 346.871 61.9268 332.704 51 320.5C43.0352 316.507 35.0352 312.507 27 308.5C13.5087 299.529 4.50866 287.362 0 272C0 261.667 0 251.333 0 241C4.85635 221.627 16.523 207.793 35 199.5C55.3233 196.344 65.99 184.511 67 164C66.6142 159.904 65.7808 155.904 64.5 152C51.2611 128.555 51.9278 105.555 66.5 83C74.9336 72.555 85.1003 64.3884 97 58.5C120.707 50.3481 141.707 54.6814 160 71.5C172.973 77.029 183.807 74.1957 192.5 63C193.645 28.0453 211.145 7.0453 245 0Z" fill="url(#paint0_linear_504_390)"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M347 171.999C365.543 171.04 374.043 179.707 372.5 197.999C371.694 200.945 370.361 203.611 368.5 205.999C324.972 249.86 281.139 293.36 237 336.499C229.307 339.781 221.64 339.781 214 336.499C189.138 311.971 164.638 287.138 140.5 261.999C135.92 249.004 139.753 239.504 152 233.499C158.224 232.438 164.224 233.105 170 235.499C188 253.499 206 271.499 224 289.499C225 290.166 226 290.166 227 289.499C265 251.499 303 213.499 341 175.499C343.227 174.558 345.227 173.391 347 171.999Z" fill="#FEFEFE"/>
                            <defs>
                            <linearGradient id="paint0_linear_504_390" x1="256" y1="0" x2="256" y2="512" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFB200"/>
                            <stop offset="1" stop-color="#FF1B00"/>
                            </linearGradient>
                            </defs>
                            </svg>
                            </span>
                    </li>
                </a>
                <li>
                    <img src="../../../../../assets/img/time-grey.svg">
                    {{eventData?.eventTime}}
                </li>
                <li>
                    <img src="../../../../../assets/img/location-pin.svg">
                    {{eventData?.location}}
                </li>
            </ul>
            <!-- <p>{{eventData?.description}}</p> -->
            <p *ngIf="eventData.description !== ''">
                {{ (eventData.description.length > firstCount) ? (eventData.description | slice:0:counter) :
                (eventData.description) }}
                <span class="cursor-pointer following_btn_color" *ngIf="eventData.description.length > firstCount"
                    (click)="toggleSkil(eventData)">
                    {{showTxt}}
                </span>
            </p>
            <div class="btn_section d-flex align-items-center justify-content-between mt-2">
                <div class="left_btn" *ngIf="userId !== eventData?.userId">
                    <button class="orange_fill_btn mr-2"
                        *ngIf="!eventData.isEventDue && eventData.isAttending  && !eventData.isEventEnded"
                        (click)="removeAttend(eventData)">{{ 'ATTENDING' | translate }}</button>
                    <button class="orange_fill_btn mr-2"
                        *ngIf="!eventData.isEventDue && !eventData.isAttending  && !eventData.isEventEnded"
                        (click)="AttendEvent(eventData)">{{ 'ATTEND' | translate }}</button>
                    <button class="orange_fill_btn mr-2"
                        *ngIf="eventData.isEventStarted && !eventData.isEventDue && !eventData.isEventEnded"
                        (click)="joinEvent(eventData)">{{ 'JOIN_NOW' | translate }}</button>
                </div>
                <div class="left_btn" *ngIf="userId === eventData?.userId">
                    <button class="orange_fill_btn"
                        *ngIf="eventData.isStartNow && !eventData.isEventDue && !eventData.isEventEnded"
                        (click)="startEvent(eventData)">{{ 'START' | translate }}</button>
                </div>
                <div class="left_btn">
                    <button class="orange_fill_btn mr-2" disabled *ngIf="eventData.isEventEnded" [disabled]="true">{{
                        'COMPLETED' | translate }}</button>
                </div>
            </div>
            <!-- s -->
        </div>

        <div class="participate_list_wraper">
            <div class="ttl">
                {{ 'PARTICIPATION' | translate }} ({{totalParticipant}})
            </div>

            <div class="participate_list">
                <ul>
                    <a>
                        <li *ngFor="let item of eventData?.participantsDetails;"
                            (click)="common.redirectToDetailsUrl(item)">
                            <img *ngIf="!item?.profilePicUrl" [src]="'../../../../../assets/img/user_icon.svg'"
                                >
                                <img *ngIf="item?.profilePicUrl && !item?.preSignedProfilePicUrls"
                                        [src]="item?.profilePicUrl">
                                <img *ngIf="item?.profilePicUrl && item?.preSignedProfilePicUrls"
                                        [src]="item?.preSignedProfilePicUrls">
                            <label>{{item.firstName}} {{item.lastName}}
                                <span *ngIf="item?.status == 'Approved'">
                                    <svg width="15" height="15" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.997" fill-rule="evenodd" clip-rule="evenodd" d="M245 0C254 0 263 0 272 0C287.83 3.32813 300.33 11.6615 309.5 25C312.901 31.8814 315.901 38.8814 318.5 46C330.651 59.0019 345.484 64.5019 363 62.5C385.314 50.4999 406.981 51.4999 428 65.5C434.167 71.6667 440.333 77.8333 446.5 84C459.362 105.383 460.362 127.383 449.5 150C447.959 166.717 453.125 180.884 465 192.5C490.332 198.997 505.999 214.831 512 240C512 250.333 512 260.667 512 271C507.151 291.355 495.151 305.855 476 314.5C467.027 316.796 458.027 318.796 449 320.5C433.951 333.382 433.451 346.548 447.5 360C461.412 383.29 460.746 406.29 445.5 429C437.786 438.391 428.619 445.891 418 451.5C398.314 458.127 378.981 457.127 360 448.5C343.609 446.185 330.109 451.352 319.5 464C311.984 489.018 295.817 505.018 271 512C260.667 512 250.333 512 240 512C223.099 507.932 209.932 498.598 200.5 484C197.77 477.809 195.437 471.476 193.5 465C176.993 446.746 157.826 443.246 136 454.5C116.864 459.811 99.1968 456.811 83 445.5C77.5 440 72 434.5 66.5 429C56.2225 414.957 52.5558 399.291 55.5 382C57.0432 375.37 59.3765 369.037 62.5 363C65.7602 346.871 61.9268 332.704 51 320.5C43.0352 316.507 35.0352 312.507 27 308.5C13.5087 299.529 4.50866 287.362 0 272C0 261.667 0 251.333 0 241C4.85635 221.627 16.523 207.793 35 199.5C55.3233 196.344 65.99 184.511 67 164C66.6142 159.904 65.7808 155.904 64.5 152C51.2611 128.555 51.9278 105.555 66.5 83C74.9336 72.555 85.1003 64.3884 97 58.5C120.707 50.3481 141.707 54.6814 160 71.5C172.973 77.029 183.807 74.1957 192.5 63C193.645 28.0453 211.145 7.0453 245 0Z" fill="url(#paint0_linear_504_390)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M347 171.999C365.543 171.04 374.043 179.707 372.5 197.999C371.694 200.945 370.361 203.611 368.5 205.999C324.972 249.86 281.139 293.36 237 336.499C229.307 339.781 221.64 339.781 214 336.499C189.138 311.971 164.638 287.138 140.5 261.999C135.92 249.004 139.753 239.504 152 233.499C158.224 232.438 164.224 233.105 170 235.499C188 253.499 206 271.499 224 289.499C225 290.166 226 290.166 227 289.499C265 251.499 303 213.499 341 175.499C343.227 174.558 345.227 173.391 347 171.999Z" fill="#FEFEFE"/>
                                    <defs>
                                    <linearGradient id="paint0_linear_504_390" x1="256" y1="0" x2="256" y2="512" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FFB200"/>
                                    <stop offset="1" stop-color="#FF1B00"/>
                                    </linearGradient>
                                    </defs>
                                    </svg>
                                    </span>
                            </label>

                        </li>
                    </a>

                </ul>
            </div>
        </div>
    </div>
</div>