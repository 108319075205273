import {Directive} from '@angular/core';

// import {SnappableContainerGV} from '../services/snappableContainerGV';

@Directive({
	selector: '[snappableContainer]'
})
export class ContainerGVDirective {
	public static SNAP_DIST = 10;
	xSnapArr = [0];
	xSnapArrCnt = [1];
	ySnapArr = [0];
	ySnapArrCnt = [1];

	constructor() {
	}

	addXPos(xCoord) {
		this.addPosHelper(xCoord, this.xSnapArr, this.xSnapArrCnt);
	}

	rmXPos(xCoord) {
		this.rmPosHelper(xCoord, this.xSnapArr, this.xSnapArrCnt);
	}

	addYPos(yCoord) {
		this.addPosHelper(yCoord, this.ySnapArr, this.ySnapArrCnt);
	}

	rmYPos(yCoord) {
		this.rmPosHelper(yCoord, this.ySnapArr, this.ySnapArrCnt);
	}

	addPosHelper(xCoord, snapArr: number[], snapArrCnt: number[]) {
		const pos = snapArr.findIndex((el) => {
			return xCoord === el;
		});

		if (pos >= 0) {
			snapArrCnt[pos]++;
		}
		else {  // new coordinate, add it in
			snapArr.push(xCoord);
			snapArrCnt.push(1);
		}
	}

	rmPosHelper(xCoord, snapArr: number[], snapArrCnt: number[]) {
		const pos = snapArr.findIndex((el) => {
			return xCoord === el;
		});
		if (pos >= 0) {
			if (--snapArrCnt[pos] <= 0) {
				// cnt goes to zero, remove from array
				snapArr.splice(pos, 1);
				snapArrCnt.splice(pos, 1);
			}
		}
	}
}
