import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {


  setLocalStore(key, data) {
    return localStorage.setItem(key, data);
  }

  getLocalStore(key: any) {
    return localStorage.getItem(key);
  }

  clearStorageFor(key) {
    return localStorage.removeItem(key);
  }

  clearStorage() {
    return localStorage.clear();
  }
}