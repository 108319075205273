import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from './local-storage.service';
import { throwError } from 'rxjs';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class HttpRequestsService {

    public hostUrl = environment.APP_URL;
    language: any;

    constructor(
        private http: HttpClient,
        private router: Router,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        private localstorege: LocalStorageService,
    ) {
        this.language = this.localstorege.getLocalStore('lang') ? this.localstorege.getLocalStore('lang') : 'en';
    }

    private getHeader(headerOptions: any, doNotSendAuthorizationParam: any) {
        const headerParams: any = {};
        headerParams['Accept-Language'] = this.language;
        if (!doNotSendAuthorizationParam) {
            headerParams['Authorization'] = this.localstorege.getLocalStore('token');
        }
        if (headerOptions) {
            Object.assign(headerParams, headerOptions);
        }
        const headers = new HttpHeaders(headerParams);
        return { headers };
    }

    post(
        url: string,
        body: any,
        doNotSendAuthorizationParam: boolean = false,
        headerOptions: any = {},
    ) {
        return new Promise((resolve, reject) => {
            const options = this.getHeader(headerOptions, doNotSendAuthorizationParam);
            this.http.post(`${this.hostUrl}${url}`, body, options).subscribe((res) => {
                resolve(res);
            }, (err) => {
                if (!this.handleError(err)) {
                    reject(err);
                }
            });
        });
    }

    uploadWithProgress(
        url: string,
        body: any,
        doNotSendAuthorizationParam: boolean = false,
        headerOptions: any = {},
    ) {

        const options = this.getHeader(headerOptions, doNotSendAuthorizationParam);
        return this.http.post(`${this.hostUrl}${url}`, body, {
            ...options, reportProgress: true,
            observe: 'events'
        })
            .pipe(
                catchError(this.errorMgmt)
            );
    }

    errorMgmt(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }

    get(url: string, params: any = {}, doNotSendAuthorizationParam: boolean = false, headerOptions: any = {}) {
        return new Promise((resolve, reject) => {
            const options = this.getHeader(headerOptions, doNotSendAuthorizationParam) as any;
            options.params = params;
            this.http.get(`${this.hostUrl}${url}`, options).pipe(map((res) => {
                return res;
            }))
                .subscribe((res) => {
                    resolve(res);
                }, (err) => {
                    this.handleError(err);
                    reject(err);
                });
        });
    }

    put(url: any, body: any, headerOptions: any = {}, doNotSendAuthorizationParam: boolean = false) {
        return new Promise((resolve, reject) => {
            const options = this.getHeader(headerOptions, doNotSendAuthorizationParam);
            this.http.put(`${this.hostUrl}${url}`, body, options).pipe(map((res) => {
                return res;
            })).subscribe((res) => {
                resolve(res);
            }, (err) => {
                this.handleError(err);
                reject(err);
            });
        });
    }

    delete(url: any, headerOptions: any = {}, doNotSendAuthorizationParam: boolean = false) {
        return new Promise((resolve, reject) => {
            const options = this.getHeader(headerOptions, doNotSendAuthorizationParam);
            this.http.delete(`${this.hostUrl}${url}`, options).pipe(map((res) => {
                return res;
            })).subscribe((res) => {
                resolve(res);
            }, (err) => {
                this.handleError(err);
                reject(err);
            });
        });
    }

    handleError(err) {
        console.log(err)
        const error = err.error.error || err.error.err || err.error.message || 'Internal server error!';
        if (err.status === 400) {
            if (err.data !== undefined && err.data.isUserDeleted === true) {
                window.location.reload();
            }
            this.toastr.error(error);
            this.spinner.hide();
            return true;
        } else if (err.status === 404) {
            this.toastr.error(error);
            this.spinner.hide();
        } else if (err.status === 409) {
            console.log(err.status, error)
            this.toastr.error(error);
            this.spinner.hide();
        } else if (err.status === 401) {
            this.toastr.error(error);
            this.spinner.hide();
            this.localstorege.clearStorage();
            this.router.navigate(['/auth/login']);
            return true;
        } else if (err.status === 500) {
            this.toastr.error(error);
            this.spinner.hide();
            return true;
        } else if (err.status === 504) {
            this.toastr.error(error);
            this.spinner.hide();
            return true;
        } else if (err.status === 0) {
            this.toastr.error('There is no network connection right now. Please try again later.');
            this.spinner.hide();
            return true;
        } else {
            return false;
        }
    }

    deleteWithBody(url: any, headerOptions: any = {}, doNotSendAuthorizationParam: boolean = false, body) {
        return new Promise((resolve, reject) => {
            const options: any = this.getHeader(headerOptions, doNotSendAuthorizationParam);
            options.body = body;
            this.http.delete(`${this.hostUrl}${url}`, options).pipe(map((res) => {
                return res;
            })).subscribe((res) => {
                resolve(res);
            }, (err) => {
                this.handleError(err);
                reject(err);
            });
        });
    }

    patch(url: any, headerOptions: any = {}, doNotSendAuthorizationParam: boolean = false) {
        return new Promise((resolve, reject) => {
            const options: any = this.getHeader(headerOptions, doNotSendAuthorizationParam);
            this.http.patch(`${this.hostUrl}${url}`, {}, options).pipe(map((res) => {
                return res;
            })).subscribe((res) => {
                resolve(res);
            }, (err) => {
                this.handleError(err);
                reject(err);
            });
        });
    }

    getForMeta(url: string) {
        return new Promise((resolve, reject) => {
            this.http.get(`${url}`).pipe(map((res) => {
                return res;
            }))
                .subscribe((res) => {
                    resolve(res);
                }, (err) => {
                    this.handleError(err);
                    reject(err);
                });
        });
    }
}