import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constant } from './shared/providers/constant';
import { HttpRequestsService } from './shared/services/http-requests.service';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

import { mergeMapTo } from 'rxjs/operators';
import { LocalStorageService } from './shared/services/local-storage.service';
import { MessagingService } from './shared/services/messaging.service';
import { UtilsService } from './shared/services/utils.service';
import { CommonAPIService } from './shared/common-api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'gymfans-pwa-angular';
  userDetails: any;
  constructor(private translate: TranslateService, private localstorege: LocalStorageService,
    private messagingService: MessagingService,
    private angularFireMessaging: AngularFireMessaging,
    private spinner: NgxSpinnerService,
    private httpRequest: HttpRequestsService,
    private utils: UtilsService,
    private common: CommonAPIService,
    private router: Router,) {
    this.translate.addLangs(['en']);
    if (!this.localstorege.getLocalStore('lang')) {
      this.localstorege.setLocalStore('lang', 'en');
    }
    this.translate.setDefaultLang('en');
    const lang: any = this.localstorege.getLocalStore('lang')
    this.translate.use(lang);
    const storageValue: any = this.localstorege.getLocalStore(Constant.LOCAL_STORAGE_KEY.USER_DETAILS);
    this.userDetails = JSON.parse(storageValue);
    if(router.url.includes("/event-details/")) {
      const item = {
        id: Number(router.url.split("/event-details/").pop())
      }
      this.common.openModal(item);
    }
  }
  async ngOnInit() {
    if(this.userDetails !== undefined) {
      this.messagingService.requestPermission()
      this.messagingService.receiveMessage()
      this.messagingService.receiveMessage();
    }
    
    let profileName = window.location.pathname.replace(/^\/([^\/]*).*$/, '$1');
    if (profileName != '' && profileName != undefined) {
      if (this.checkString(profileName) == true) {
        profileName = profileName.replace(/%20/g, " ");
        await this.userData(profileName);
      }
    }
  }
  checkString(string) {
    return string.match(/[!@#$%^&*()_+\-=\[\][0-9]{};':"\\|,.<>\/?]+/) ? true : false;
  }
  async userData(profileName) {

    let data = {
      "page": 1,
      "limit": 10,
      "profileName": profileName
    }
    const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.PROFILE_DATA, data, true);
    if (result.code === 200) {
      const token = this.localstorege.getLocalStore(Constant.LOCAL_STORAGE_KEY.USER_DETAILS);
      if (token != null && token != undefined && token != '') {
        let userData = JSON.parse(token);
        if (userData.id === result.data.id) {
          this.router.navigate(['/profile']);
        } else if (userData.id !== result.data.id) {
          this.router.navigate([`/other-user/${result.data.id}`]);
        } else {
          this.router.navigate(['/auth/login']);
        }
      } else {
        this.router.navigate(['/auth/login']);
      }
    } else {

      this.router.navigate(['/auth/login']);
    }
  }

  // @HostListener('window:beforeunload', ['$event'])
  // beforeunloadHandler(event): void {
  //   // Do something
  //   window.location.replace("www.google.com")
  // }
}
